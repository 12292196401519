<template>

    <div class="row" ref="itemView">
  
      <div class="col-12">
  
        <card>
          <div slot="header">
            <div class="header-content">
              <div class="form-title">
                <h4 class="card-title">
                  {{ formTitle }}
                </h4>
              </div>
            </div>
          </div>
  
          <div id="contactus-message" class="card-body">
            <div class="container">
              <div class="row contact-info">
                <div class="col-12 col-md-12 col-lg-12">
                 <div class="member-box">
                   <span class="title-label">Client Information</span>
                   <div class="row">
                     <div class="col-md-9">
                       <div class="row">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Full Name</span>
                             <span class="category-value">  {{ getValueOrDash(itemDetails.client, 'full_name') }} </span>
                           </p>
                         </div>

                       </div>

                       <div class="row">
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Email</span>
                             <span v-if="itemDetails && itemDetails.client" class="category-value"> {{ itemDetails.client.email }}</span>
                           </p>
                         </div>
                         <div class="col-md-6 typo-line">
                           <p>
                             <span class="category category-2">Phone</span>
                             <span v-if="itemDetails && itemDetails.client" class="category-value">{{ itemDetails.client.phone }}</span>
                           </p>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
                 <div class="member-box address">

                    <span class="title-label">Address Details</span>
                  <div class="address-box">
                    <span class="address-label">Shipping Address</span>
                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">First Name</span>
                          <span class="category-value"> {{ itemDetails.shipping_first_name }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Last Name</span>
                          <span class="category-value"> {{ itemDetails.shipping_last_name }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Phone Number</span>
                          <span class="category-value"> {{ itemDetails.shipping_phone_number }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Address line 1</span>
                          <span class="category-value"> {{ itemDetails.shipping_address_line_1 }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Address line 2</span>
                          <span class="category-value"> {{ itemDetails.shipping_address_line_2 }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Postal code</span>
                          <span class="category-value"> {{ itemDetails.shipping_postal_code }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Country</span>
                          <span class="category-value"> {{ itemDetails.shipping_country }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">State</span>
                          <span class="category-value"> {{ itemDetails.shipping_state }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">City</span>
                          <span class="category-value"> {{ itemDetails.shipping_city }}</span>
                        </p>
                      </div>
                    </div>


                  </div>

                  <div class="address-box">
                    <span class="address-label">Payment Address</span>
                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">First Name</span>
                          <span class="category-value"> {{ itemDetails.payment_first_name }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Last Name</span>
                          <span class="category-value"> {{ itemDetails.payment_last_name }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Phone Number</span>
                          <span class="category-value"> {{ itemDetails.payment_phone_number }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Address line 1</span>
                          <span class="category-value"> {{ itemDetails.payment_address_line_1 }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Address line 2</span>
                          <span class="category-value"> {{ itemDetails.payment_address_line_2 }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Postal code</span>
                          <span class="category-value"> {{ itemDetails.payment_postal_code }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Country</span>
                          <span class="category-value"> {{ itemDetails.payment_country }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">State</span>
                          <span class="category-value"> {{ itemDetails.payment_state }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">City</span>
                          <span class="category-value"> {{ itemDetails.payment_city }}</span>
                        </p>
                      </div>
                    </div>


                  </div>

                </div>

                </div>
              </div>

              <div>
                <div class="member-box">
                  <span class="title-label">Order detais </span>

                      <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Order Status</span>
                          <span class="category-value"> {{ itemDetails.status }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Payment Method</span>
                          <span class="category-value"> {{ itemDetails.payment_method.name }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Shipping Method</span>
                          <span class="category-value"> {{ itemDetails.shipping_method.name }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 typo-line">
                        <p>
                          <span class="category category-2">Created at</span>
                          <span class="category-value"> {{ itemDetails.created_at }}</span>
                        </p>
                      </div>
                    </div>

                    <!--//Notes-->
                    <div class="row">
                        <div class="col-md-6 typo-line">
                          <p>
                            <span class="category category-2">Notes</span>
                            <span class="category-value"> {{itemDetails.notes }}</span>
                          </p>
                        </div>
                    </div> 

                    <div v-if="itemDetails.shipping_method.code == 'pickup'">
                    <div>
                      <l-button :disabled="itemDetails.is_pickup || submitting"  @click.prevent="readyPikup()"
                        nativeType="submit"
                        type="info" wide>Ready for pickup
                      </l-button>
                    </div>

                    </div>
                </div>
              </div>
  
  
              <div>
                <div class="member-box">
                  <span class="title-label">Products </span>
                  <div v-if="itemDetails.items">
                    <div class="message-content" v-for="product in itemDetails.items" style="border-bottom: 1px solid #ccc;margin-bottom: 20px">
  
                        <div class="">
                            <div class="typo-line">
                                <p>
                                <span class="category category-2">Product Name</span>
                                <span class="category-value"> {{product.name }}</span>
                                </p>
                            </div>
                        </div>

                        <div class="">
                            <div class="typo-line">
                                <p>
                                <span class="category category-2">Price</span>
                                <span class="category-value"> {{product.price }}$</span>
                                </p>
                            </div>
                        </div>
                        <div class="">
                            <div class="typo-line">
                            <p>
                                <span class="category category-2">Quantity</span>
                                <span class="category-value"> {{product.quantity }}</span>
                            </p>
                            </div>
                        </div>

                   </div>
                  </div>
                </div>
  
              </div>

              <div>
                <div class="member-box">
                  <span class="title-label">Totals </span>
                  <div v-if="itemDetails.totals">
                    <div class="message-content" v-for="total in itemDetails.totals" style="border-bottom: 1px solid #ccc;margin-bottom: 20px">
  
                      <div class="">
                        <div class="typo-line">
                              <p>
                                <span class="category category-2">{{ total.total_name   }}</span>
                                <span class="category-value"> {{total.total_value }}$</span>
                              </p>
                        </div>
                      </div>
                    
                   </div>
                  </div>
                </div>
  
              </div>

              
            </div>
          </div>
  
          <div class="card-footer text-right">
  
          </div>
        </card>
  
  
      </div>
      
    </div>
  </template>
  <script>
  // import { jsPDF } from "jspdf";
  
  export default {
  
    components: {},
  
    data() {
      return {
        id: undefined,
        formTitle: "",
        submitting: false,
        itemDetails: null,
        imagePath: process.env.VUE_APP_SERVER_IMAGE_URL,
        showMenuModalVisibility: false,
        currentMenu: [],  
      };
    },
  
    mounted() {
  
      this.loader = this.$loading.show({
        container: this.$refs.itemView
      });
  
      this.id = this.$route.params['id'];
      this.formTitle = "Order Details";
      if (this.id !== undefined) {
        this.getItem();
      } else {
        this.$notify({
          message: "Order Not Found",
          timeout: 2000,
          type: 'danger'
        });
        this.loader.hide();
      }
    },
  
  
    methods: {
  
      getItem() {
        this.axios.post("orders/get", {id: +this.id}).then((response) => {
          this.itemDetails = response.data;
          this.loader.hide();
        }).catch((error) => {
          if (error.response.status === 404) {
            this.$notify({
              message: "Order Not Found",
              timeout: 2000,
              type: 'danger'
            });
            this.loader.hide();
  
          } else {
            console.error(error);
          }
        })
      },
      getValueOrDash(obj, key) {
        if (obj && obj[key]) {
          return obj[key];
        }
        return "-";
      },
      readyPikup(){
        this.submitting = true;
        this.axios.post("orders/"+this.id+"/ready-pickup").then((response) => {
          this.$notify({
          message: 'Pickup email sent',
          timeout: 1000,
          type: 'success'
        })
        this.submitting = false;
        this.getItem();
        }).catch((error) => {
          if (error.response.status === 400) {
            this.$notify({
              message: "Order could'nt be marked as ready for pickup",
              timeout: 2000,
              type: 'danger'
            });
            this.loader.hide();
  
          } else {
            console.error(error);
          }
          this.submitting = false;
        })
      }
    }
  }
  </script>
  
  <style>
  .header-content{
    display: flex;
    justify-content: space-between;
  }
  .typo-line{
    padding-left: 65px;
    margin-bottom: 20px;
  }
  .contact-info{
    margin-bottom: 20px;
  }
  .category{
    color: #888888;
  }
  .category-2{
    top: 11px !important;
  }
  .category-value{
    margin-left: 50px;
  }
  /*.message-content{*/
  /*  font-size: 16px !important*/
  /*}*/
  .member-box {
    position: relative;
    border: 1px solid black;
    padding: 33px;
    padding-left: 30px;
    margin-bottom: 30px;
  }
  .title-label {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: white;
    padding: 0 5px;
  }
  .member-profile-image{
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  .address-box {
    width: 50%;
   }
   .member-box.address {
    display: flex;
    justify-content: space-between;
    }
  </style>
  